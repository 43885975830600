/* Customization Guide available on:
  https://gatsby-blog-mdx-demo.pages.dev/2020/05/4-customize/
  ======================================== */
const config = {
  /* Site
  ========================================= */
  title: `gatsby-blog-mdx`,        // Displayed in header
  description: `This is my blog`,  // Site description
  maxWidth: 768,               // Max width of website
  enableAbout: true,               // Enables about page
  siteUrl: `https://gatsby-blog-mdx-demo.pages.dev/`,
  useScrollIndicator: true,

  /* Profile
  ========================================= */
  author: `Panda`,                          // Name shows on profile
  profileDescription: "My Simple blog",     // Shows under author name
  profileImageName: `profile.jpeg`,         // Place profile.jpg/.jpeg/.png in _assets folder
  location: "Austin, TX",                   // Location under profileDescription. "" --> disabled
  footerLink: "",                           // Link to page when you click footer name

  /* Social Media Links
      accountName & emailAddress: leave blank to disable
      showHeaderIcon: shows social media icon in header. When true, must have account name set
  ========================================= */
  socialMediaLinks: {
    email:     { emailAddress: "", showHeaderIcon: true },
    github:    { accountName: "ellismin", showHeaderIcon: true },
    facebook:  { accountName: "", showHeaderIcon: true },
    instagram: { accountName: "", showHeaderIcon: true },
    twitter:   { accountName: "", showHeaderIcon: true },
    linkedIn:  { accountName: "", showHeaderIcon: true }, // Use URL after "linkedin.com/"
    medium:    { accountName: "", showHeaderIcon: true },   // Use URL after "medium.com/@"
  },

  /* Social Media Share Buttons--available below every post
  ========================================= */
  shareButtons: { email: false, facebook: true, twitter: true, reddit: false, linkedIn: false },

  /* Comments
  ========================================= */
  comments: {
    facebook: { enabled: false, appId: "" },      // appId: optional, but needed to moderate fb comments
    disqus: { enabled: false, shortName: "" },   // Check your shortName on https://disqus.com
    utterances: { enabled: true, repoUrl: "ellismin/gatsby-blog-mdx-demo-comments" }, // i.e. ellismin/gatsby-blog-mdx
  },

  /* More Custom Settings
  ========================================= */
  defaultTheme: "light",                      // Options: dark / light -- applied to first visitors
  showTimeToRead: false,                      // Shows time to read for each post in main page
  breakCodeLines: false,                      // Breaks long code lines instead of horizontal scroll
  faviconSrc: `_assets/icon-code.png`,        // Favicon
  gaTrackingId: `UA-167362750-1`,             // Your google analytics tracking id--i.e. UA-*****
  googleAdSenseId: ``,                        // Your google AdSense client id--i.e. ca-pub-****
}

module.exports = config
